import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} defaultExpanded>
        <AccordionSummary
          sx={{ background: '#20293a', color: 'white', typography: { fontWeight: 'bold', fontSize: '1.2rem' } }}
          expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          What is Openbook Market ID?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{background: '#b6bcc8'}}>
          <Typography>
          Openbook Market ID is a unique ID that allows your coin project to be traded on defi platforms. All major defi platforms in the Solana network e.g. (Raydium.io) requires obtaining this identity.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          sx={{ background: '#20293a', color: 'white', typography: { fontWeight: 'bold', fontSize: '1.2rem' } }}
          expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
          aria-controls="panel2bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          How is Affordable Cost Ensured?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{background: '#b6bcc8'}}>
          <Typography>
          As you know, the average market id creation fee is around "3 SOL", but thanks to our innovative solutions and web3.js technology, market id creation reduces the network and rental costs to only around 0.2 - 0.3 SOL. Our efforts to reduce costs continue. You can review our work in our Github repository below.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          sx={{ background: '#20293a', color: 'white', typography: { fontWeight: 'bold', fontSize: '1.13rem' } }}
          expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
          aria-controls="panel3bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          What is Quote Mint?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{background: '#b6bcc8'}}>
          <Typography>
          Quote Mint; This is the area where you determine which currency you would prefer to trade with on the defi platforms of your coin project.<br /> You can choose one of these 3 currencies:<br /> SOL - USDC - USDT. Contract addresses are listed below.  <br />
USDC: EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v  <br />
USDT: Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB  <br />
Wrapped SOL: So1111111111111111111111111111111111111111112 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          sx={{ background: '#20293a', color: 'white', typography: { fontWeight: 'bold', fontSize: '1.14rem' } }}
          expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
          aria-controls="panel4bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
          How Should Tickers Settings ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{background: '#b6bcc8'}}>
          <Typography>
          You can configure the settings according to the examples below. <br />
            Min. Order Size: Order book minimum order quantity. <br />
            Example When set to 4 - 0.0001 units can be ordered. <br />
            Example When set to 0 - Up to 1 item can be ordered. <br />
            Example set to 1 - Up to 0.1 units can be made to order. <br />
            Price Tick: These are the numbers in which the price is displayed. <br />
            Example When set to 5 - The price of the token is displayed in 5 decimal places, such as 11.12345. <br />
            Example When set to 4 - The price of the token is displayed in 4 decimal places such as 11. <br />
            Note: Minimum order size cannot be larger than the decimal digits of the base token. <br />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
